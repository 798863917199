<template>
  <v-container class="font-family-montserrat">
    <div class="d-relative">
      <v-img cover width="100%" :height="300" src="http://images2.fanpop.com/images/photos/6700000/Pet-Society-Wallpaper-playfish-games-6728980-1600-1200.jpg"></v-img>
      <div class="d-absolute" style="bottom:0;right:0;">
        <v-img contain :width="250" :height="250" src="https://static-asset-delivery.hasbroapps.com/9688c1ee284bc3452ba52dce87d8c97a4c2b948c/2e1782813c56741c2e998f1fbfc7243d.png"></v-img>
      </div>
      <v-card class="d-absolute" style="bottom:10px;right:10px;">
        <v-card-title>Hamochi Town</v-card-title>
        <v-card-subtitle>Build a heartwarming town with your students together</v-card-subtitle>
      </v-card>
    </div>
    <div>
      <div>Be the mayor of your classroom. Assign students exciting missions. Decorate the town together. Hamochi Town is the most engaging and cutest classroom game ever!</div>
      <h3>Cool Features</h3>
      <ul>
        <li>Assign homeworks</li>
        <li>Reward/penalise students</li>
        <li>Monitor their performance</li>
        <li>Host events</li>
        <li>Tons of mini games for students to learn and play</li>
      </ul>
    </div>
    
    <div>
      <h1>Great Promotion! 100% OFF</h1>
      <div>Thanks for anonymous donors, we now offer the RM1,200/annual solution for FREE to 100 Malaysia government schools before end of this month. Apply now while promotion lasts! Terms and conditions apply.</div>
      <v-btn color="primary" x-large>Apply Now</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    //
  }),
};
</script>
<style scoped>
  .d-relative {
    position: relative;
  }
  .d-absolute {
    position: absolute;
  }
  .font-family-montserrat {
    font-family: "Montserrat", sans-serif !important;
  }
</style>
